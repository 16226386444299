@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.HomePage {
    .CmsPage {
        &,
        &-Wrapper {
            margin-block-end: 0;
        }
    }

    &_isPrerendered {        
        .widget:has(a[href*="gotowe-projekty-lazienek"]) {
            // hide "Usługi projektowania" section
            display: none;
        }
    }
}
