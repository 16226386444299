@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CmsPage {
    margin-block-start: 0;

    &-Wrapper {
        margin-block-start: 0;
        min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
        overflow: hidden;

        p {
            &:last-child {
                margin: 0;
            }
        }

        @include mobile {
            padding-inline-start: 16px;
            padding-inline-end: 16px;
        }
    }

    &-Content {
        h2 {
            @include mobile {
                text-align: start;
            }
        }
    }

    &-SectionPlaceholder {
        min-height: calc(100vh - var(--header-height, 0px) - var(--demo-notice-height, 0px));
    }

    .ProductListWidget {
        margin: 24px 0;
        padding-block-end: 0;

        @include mobile {
            margin: 16px 0;
        }

        @include desktopAndAbove {
            overflow-x: clip;
            padding-inline: 20px;
        }

        &-Redirect {
            position: absolute;
            inset-inline-end: 0;
            inset-block-start: 5px;
            padding-block-end: 3px;
            color: $color-primary;
            font-size: $fs-xs;
            font-weight: $fw-medium;
            border-bottom: 1px solid $color-primary;

            @include mobile {
                inset-block-start: unset;
                margin-block-start: 3px;
            }
        }

        &_extended {
            margin-block-start: 48px;
            padding-block-end: 80px;
            margin-block-end: 0;

            @include tablet {
                padding: 0;
            }

            h2 {
                margin-block-end: 8px;
                letter-spacing: -1px;

                @include desktopAndAbove {
                    margin-inline-start: 25px;
                }
            }

            .SliderCounterNav {
                @include mobile {
                    background: $white;
                }
            }

            &::before {
                background: $color-primary-lighter;
                content: "";
                position: absolute;
                height: 323px;
                inset-block-start: -48px;
                z-index: -1;

                @include desktopAndAbove {
                    inset-inline-start: 0;
                    width: 100%;
                }

                @include mobileAndTablet {
                    inset-block-start: -32px;
                    inset-inline: -32px;
                    height: 228px;
                }

                @include mobile {
                    inset-block-start: -16px;
                    inset-inline: -16px;
                }
            }

            @include mobile {
                margin-block-start: 32px;
                padding-block-end: 50px;
            }

            .ProductListPage {
                @include desktopAndAbove {
                    max-width: calc(100% - 70px);
                    margin-inline-start: 25px;
                }
            }

            .ProductLabel {
                margin: 15px;

                @include desktop {
                    padding: 0;
                }
            }

            .ProductCard {
                &-ShortDescription,
                &-AddToCart {
                    display: none;
                }

                &-Content {
                    .ProductCard-Discount {
                        display: none;
                    }
                }

                &:hover {
                    .ProductCard-VisibleOnHover {
                        .AddToCart {
                            display: block;
                        }
                    }
                }
            }

            .swiper-slide-active {
                .ProductCard {
                    &-ShortDescription {
                        display: -webkit-box;
                    }
                    
                    .ProductLabel {
                        inset-inline-start: 35%;
                    }
                }
            }
        }

        .SliderCounterNav {
            margin-block-end: 16px;

            @include desktopAndAbove {
                margin: 0 5px 16px;
            }
        }

        .ProductListPage {
            margin: 0;
            padding: 0;

            @include desktopAndAbove {
                grid-template-columns: repeat(4, 1fr);
            }

            &_isSlider {
                display: block;

                .SliderNext,
                .SliderPrev {
                    opacity: 0;

                    @include mobileAndTablet {
                        @include visually-hidden;
                    }
                }

                &:hover {

                    /* stylelint-disable-next-line */
                    .SliderNext,
                    .SliderPrev {
                        opacity: 1;
                    }
                }

                &.ProductListWidget-Page_extended {
                    .slick-slide {
                        @include mobile {
                            /* stylelint-disable-next-line declaration-no-important */
                            width: 288px !important;
                            /* stylelint-disable-next-line declaration-no-important */
                            display: flex !important;
                            height: auto;
                        }
                    }
                }

                .slick-slide {
                    padding: 0 5px;

                    >div {
                        @include mobile {
                            width: 100%;
                        }
                    }
                }

                .slick-list {
                    overflow: visible;

                    @include tablet {
                        padding-block-end: 100px;
                    }
                }

                .slick-slider {
                    overflow: hidden;
                    margin-block-end: -25px;
                    padding-block-end: 80px;
                    width: calc(100% + 32px);
                    margin-inline: -16px;
                    padding-inline: 16px;
                    z-index: 1;

                    @include mobile {
                        margin-block-end: -50px;
                        padding: 0;
                        margin-inline: -5px;
                        width: calc(100% + 10px);
                    }

                    @include tablet {
                        padding: 0 0 40px;
                    }

                    &.type_extended {
                        .ProductCard {
                            &-ShortDescription {
                                display: none;
                            }
                        }
                    }
                }

                .SliderProgressBar {
                    @include mobileAndTablet {
                        margin-block-start: 48px;
                    }
                }
            }
        }

        &_placeholder {
            @include aspect-ratio(139.7%, "& > *");

            @include desktop {
                @include aspect-ratio(44.1%, "& > *");
            }
        }

        &_extended_placeholder {
            @include mobile {
                margin-block-start: 14px;
            }

            @include aspect-ratio(168%, "& > *");

            @include desktop {
                @include aspect-ratio(44.1%, "& > *");
            }
        }
    }

    .widget {
        overflow-x: unset;
    }

    .ProductListPage {
        &_isSlider {
            .SliderPrev {
                inset-inline-start: 0;
            }

            .SliderNext {
                inset-inline-end: 0;
            }
        }
    }

    .ExtendedSlider-Wrapper {
        .swiper-slide {
            transition: none;
        }

        .SliderPrev {
            inset-inline-start: -16px;

            @include desktopAndAbove {
                inset-inline-start: -25px;
            }
        }

        .SliderNext {
            inset-inline-end: -16px;

            @include desktopAndAbove {
                inset-inline-end: -45px;
            }
        }

        .slider-counter-wrapper {
            >span {
                font-size: $fs-xxs;
            }
        }

        .swiper-slide-active {
            .ProductCard {
                @include desktopAndAbove {
                    &:hover {
                        .ProductCard {
                            box-shadow: none;
                            border: solid 1px transparent;

                            &-Variants,
                            &-VisibleOnHover,
                            &-Reviews {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .Slider {
        &_placeholder {
            margin-block: 14px 32px;
        }

        @include aspect-ratio(83%, ".slick-slider");

        @include desktop {
            @include aspect-ratio(39.6%, ".slick-slider");
        }
    }

    .ProductListWithoutHotspotsWidget {
        &_placeholder {
            @include aspect-ratio(139.7%, "& > *");

            @include desktop {
                @include aspect-ratio(59.95%, "& > *");
            }
        }
    }
}
