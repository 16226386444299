@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NoMatch {
    @include flex($align: center, $justify: center, $dir: column);

    &-NoMatchContainerInfo {
        padding: 40px 0;
        text-align: center;
    }

    &-Info {
        font-size: $fs-m
    }
}
